import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import Button from '../../Button';
import Link from '../../Link';
import { propTypes as linkPropTypes } from '../../../components/01_atoms/Link';
import useScreenSize from '../../../hooks/useScreenSize';

import styles from './index.module.scss';

const BBStatsSectionWithAnimation = ({ heading, items, button = null }) => {
  const { width } = useScreenSize();
  const { ref, inView } = useInView({
    /* Optional options */
    triggerOnce: true,
    rootMargin: '-40% 0% -40% 0%',
  });

  return (
    <div
      ref={ref}
      className={`bb ${styles['bb-stats-section-with-animation']} ${width >= 1200 && inView ? styles['bb-stats-section-with-animation--full-width'] : ''}`}
    >
      <div className={styles['bb-stats-section-with-animation__background-mask']} />
      <div className="container">
        <div className={styles['bb-stats-section-with-animation__content']}>
          <div className={styles['bb-stats-section-with-animation__heading-with-items']}>
            <h2 className={styles['bb-stats-section-with-animation__heading']}>{heading}</h2>
            <div className={styles['bb-stats-section-with-animation__items']}>
              {items.map((item) => (
                <div key={item.heading} className={styles['bb-stats-section-with-animation__item']}>
                  <div className={styles['bb-stats-section-with-animation__item-heading']}>
                    {item.heading}
                  </div>
                  <div className={styles['bb-stats-section-with-animation__item-subheading']}>
                    {item.subheading}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {button && button.nextLink && (
            <Link {...button.nextLink}>
              <Button
                className={styles['bb-stats-section-with-animation__button']}
                withArrow
                type="secondary"
              >
                {button.label}
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

BBStatsSectionWithAnimation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      subheading: PropTypes.string,
    }),
  ).isRequired,
  heading: PropTypes.string.isRequired,
  button: PropTypes.shape({
    nextLink: PropTypes.shape(linkPropTypes),
    label: PropTypes.string,
  }),
};

export default BBStatsSectionWithAnimation;
